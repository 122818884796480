import { create } from 'zustand';
import { StoreCategory, StoreManufacturer, StoreOrder, StoreOrderProduct, StoreProduct, UserType } from 'interfaces/api';
import { useCallback, useMemo } from 'react';
import { createSelectors } from 'utils/helpers';
import { useAuthUser } from 'modules/auth/providers';
import { devtools } from 'zustand/middleware';
import { useApi } from 'providers';
import { find, findIndex, omit, sumBy } from 'lodash';

interface LabstorePropsState {
  aid: number;
  loading: boolean;
  opened: boolean;
  categories: StoreCategory[];
  manufacturers: StoreManufacturer[];
  selectedProduct: StoreProduct;
  selectedCategoryId: number;
  selectedManufacturerId: number;
  basket: StoreOrderProduct[];
}

interface LabstoreSetState {
  open: () => void;
  close: () => void;
  reset: () => void;
  ambiguous: () => boolean;
  setAid: (aid: number) => void;
  setLoading: (loading: boolean) => void;
  setSelectedCategoryId: (id: number) => void;
  getSelectedCategory: () => StoreCategory;
  setSelectedManufacturerId: (id: number) => void;
}

const initialState: LabstorePropsState = {
  aid: undefined,
  loading: false,
  opened: false,
  categories: [],
  manufacturers: [],
  selectedCategoryId: null,
  selectedManufacturerId: undefined,
  selectedProduct: undefined,
  basket: [],
};

export const useLabstoreStore = create<LabstorePropsState & LabstoreSetState>()(devtools((set, get) => ({
  ...initialState,
  setAid: aid => set({ aid }),
  setLoading: loading => set({ loading }),
  open: () => set({ opened: true }),
  close: () => set({ opened: false }),
  reset: () => set(initialState),
  ambiguous: () => get().opened && !get().aid,
  setSelectedCategoryId: selectedCategoryId => set({ selectedCategoryId }),
  getSelectedCategory: () => find(get().categories, { id: get().selectedCategoryId }),
  setSelectedManufacturerId: selectedManufacturerId => set({ selectedManufacturerId }),
})));

export const useLabstoreSelectors = createSelectors(useLabstoreStore).use;

export const useLoadStoreConfig = () => {

  const {
    storeCategories: { getStoreCategoryTreeForDoctor },
    storeManufacturers: { listStoreManufacturers },
  } = useApi();

  const aid = useLabstoreSelectors.aid();

  return useCallback(async (aid: number) => {

    if (aid !== undefined) {
      useLabstoreStore.setState({ loading: true });
      const categories = await getStoreCategoryTreeForDoctor({ aid });
      const manufacturers = await listStoreManufacturers();
      useLabstoreStore.setState({ categories, manufacturers, loading: false });
    }

  }, [aid, getStoreCategoryTreeForDoctor, listStoreManufacturers]);

};

export const useSetLabstoreAid = () => {

  const loadConfig = useLoadStoreConfig();
  const { storeOrders: { setCurrentUserOrder } } = useApi();

  return useCallback(async (aid: number) => {
    useLabstoreStore.setState({ aid });
    await setCurrentUserOrder({ aid });
    await loadConfig(aid);
  }, [setCurrentUserOrder, loadConfig]);

};

export const useOpenLabstore = () => {

  const user = useAuthUser();

  const setAid = useSetLabstoreAid();
  const open = useLabstoreSelectors.open();

  return useCallback(async () => {
    open();
    if (user?.type === UserType.ARZ) {
      await setAid(user.entityId);
    }
  }, [user]);

};

export const productInBasket = (product: StoreProduct) => {
  const basket = useLabstoreStore.getState().basket;
  return find(basket, { productId: product.id });
};

export const useUpsertStoreProduct = () => {

  const { storeOrders: { setCurrentUserOrder } } = useApi();

  return useCallback(async (product: StoreProduct, amount: number) => {

    const inBasket = productInBasket(product);
    const updatedProduct = { product, amount: (inBasket?.amount || 0) + amount, productId: product.id };

    const currentBasket = [...useLabstoreStore.getState().basket];

    inBasket ? currentBasket.splice(findIndex(currentBasket, { productId: product.id }), 1, updatedProduct) : currentBasket.push(updatedProduct);

    useLabstoreStore.setState({ basket: currentBasket });
    await setCurrentUserOrder({ items: currentBasket.map(b => omit(b, 'product')) });

  }, [setCurrentUserOrder]);
};

export const useRemoveStoreProduct = () => {

  const { storeOrders: { setCurrentUserOrder } } = useApi();

  return useCallback(async (product: StoreProduct) => {
    const basket = useLabstoreStore.getState().basket.filter(b => b.productId !== product.id);
    useLabstoreStore.setState({ basket });
    await setCurrentUserOrder({ items: basket.map(b => omit(b, 'product')) });
  }, [setCurrentUserOrder]);
};

export const useStoreBasketCount = () => {
  const basket = useLabstoreSelectors.basket();
  return useMemo(() => sumBy(basket, b => b.amount), [basket]);
};

export const useSetCurrentOrder = () => {

  const loadConfig = useLoadStoreConfig();
  const resetStore = useLabstoreSelectors.reset();
  const aid = useLabstoreSelectors.aid();

  return useCallback(async (currentOrder: StoreOrder) => {
    if (currentOrder) {
      useLabstoreStore.setState({ aid: currentOrder.aid, basket: currentOrder.items || [] });
      if (aid !== currentOrder.aid) {
        await loadConfig(currentOrder.aid);
      }
    } else {
      resetStore();
    }
  }, [loadConfig, aid]);

};
