import React from 'react';
import { useLabstoreSelectors, useSetLabstoreAid } from 'modules/store/store/useLabstoreStore';
import { Modal, UserSelect } from 'components';
import { Doctor, Product, UserType } from 'interfaces/api';
import messages from 'messages';

export const DoctorSelectModal = () => {

  const opened = useLabstoreSelectors.opened();
  const close = useLabstoreSelectors.close();

  const aid = useLabstoreSelectors.aid();
  const setAid = useSetLabstoreAid();

  return (
    <Modal
      open={opened && !aid}
      title={messages.orders.chooseDoctor}
      footer={null}
      onCancel={close}
      children={() => (
        <UserSelect
          noBorder
          standalone
          publicOnly
          checkboxes={false}
          type={UserType.ARZ}
          product={Product.LS}
          onChange={async (doctor: Doctor) => {
            await setAid(doctor.aid);
          }}
        />
      )}
    />
  );

};
