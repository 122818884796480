import React from 'react';
import { OrderReason, OrderRequirementSchema, OrderWizardLocalization } from 'interfaces/api';
import { ModalFormControl } from 'components';
import { faLayerGroup } from '@fortawesome/pro-light-svg-icons';
import messages from 'messages';
import { useApi } from 'providers';
import { useCurrentOrder, useOfficeDoctorSelectors, useOrdersConfig, useReloadWizardSettings } from 'modules/orders/providers';
import { useBasketContext, useOrderWizardParametersSelectors } from 'modules/orders/containers/OrderWizard/providers';
import { splitEnumOptions } from 'utils/helpers';
import { FormLayout } from 'containers';
import { isNull, keys, omitBy, pick } from 'lodash';
import { DiagnosesFormItem } from 'modules/orders/components';

const labels = messages.orders.wizard.profiles;
const additionalLabels = messages.orders.additionalFields;

export const UpdateProfileControl = () => {

  const { profiles: { updateProfile } } = useApi();

  const { aid, localisation } = useOfficeDoctorSelectors.officeDoctor();
  const reloadWizardSettings = useReloadWizardSettings();
  const currentOrder = useCurrentOrder();
  const isPoolMode = useOrderWizardParametersSelectors.pool();

  const { uniqRequirements, profiles } = useBasketContext();
  const { preferences } = useOrdersConfig();

  const { entityId: id, name, diagnosis, freeText, orderReason, selectedDiagnoses } = profiles[0] || {};
  const requirements = uniqRequirements.map(r => pick(omitBy(r, isNull), keys(OrderRequirementSchema)));

  return (
    <ModalFormControl
      icon={faLayerGroup}
      label={labels.update.title}
      tooltip={{ placement: 'left' }}
      modal={{
        narrow: true,
        okText: messages.general.send,
        title: labels.update.title,
      }}
      notifications={labels.update.notifications}
      form={{
        initialValue: {
          aid,
          requirements,
          name,
          diagnosis,
          freeText,
          orderReason,
          selectedDiagnoses,
          costUnit: currentOrder?.costUnit,
          toAllOthers: false,
          withFreeTexts: false,
        },
        request: (values) => {
          updateProfile({
            aid,
            id,
            requirements,
            name: values.name,
            diagnosis: values.diagnosis,
            freeText: values.freeText,
            selectedDiagnoses: values.selectedDiagnoses,
            orderReason: values.orderReason,
            toAllOthers: values.toAllOthers,
            withFreeTexts: values.withFreeTexts,
          });
        },
        onSuccess: () => {
          reloadWizardSettings(aid);
        },
        children: ({ Input, Radio, Switch, value, onChange }) => (
          <FormLayout>
            <Input property={'name'} label={labels.name} autoFocus/>
            {(!preferences.ordersHideDiagnosisFields && preferences.orderWizardProfilesSetDiagnoseText) && (
              <Input property={'diagnosis'} label={messages.general.diagnosis}/>
            )}
            {preferences.orderWizardProfilesSetFreeText && (
              <Input property={'freeText'} label={messages.orders.additionalFields.freeText}/>
            )}
            {!preferences.ordersHideDiagnosisFields && (
              <DiagnosesFormItem costUnit={currentOrder?.costUnit} value={value} onChange={onChange}/>
            )}
            {localisation !== OrderWizardLocalization.CHE && !preferences.orderWizardHideAdditionalInfo && (
              <Radio
                property={'orderReason'}
                values={splitEnumOptions(OrderReason, additionalLabels.orderReasons)}
                optionType="button"
              />
            )}
            {!isPoolMode && (
              <Switch property={'toAllOthers'} label={labels.controls.copy.toAllOthers.title}/>
            )}
            <Switch property={'withFreeTexts'} label={labels.withFreeTexts.title}/>
          </FormLayout>
        ),
      }}
    />
  );
};
